import React from 'react';
// Partners' logo
import HPLogo from "../../assets/images/partners/hp.png";
import dellLogo from "../../assets/images/partners/dell.png";
import nimbleStorageLogo from "../../assets/images/partners/nimble-storage.png";
import netAppLogo from "../../assets/images/partners/netapp.png";
import veeamLogo from "../../assets/images/partners/veeam.png";
import barracudaLogo from "../../assets/images/partners/barracuda.png";
import coreViewLogo from "../../assets/images/partners/coreview.png";
import commVaultLogo from "../../assets/images/partners/commvault.png";
import VMWareLogo from "../../assets/images/partners/vmware.png";
import ciscoLogo from "../../assets/images/partners/cisco.png";
import sophosLogo from "../../assets/images/partners/sophos.png";
import f5Logo from "../../assets/images/partners/f5.png";
import microsoftLogo from "../../assets/images/partners/microsoft.png";
import trendMicroLogo from "../../assets/images/partners/trend-micro.png";
import forescoutLogo from "../../assets/images/partners/forescout.png";
import fortinetLogo from "../../assets/images/partners/fortinet.png";
import BCInTheCloudLogo from "../../assets/images/partners/bc-in-the-cloud.png";
import alienVaultLogo from "../../assets/images/partners/alien-vault.png";
import styled from "styled-components";

const Partners = styled.section`
  margin: 5em 5% 10em;

  .partners__heading {
    text-align: center;
  }

  .partners__logos {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    gap: 2em;
    margin-top: 3em;
  }

  .partners__logo-c {
    border-radius: 0.625em;
    flex: 145px;
    max-width: 160px;
    min-height: 100px;
    overflow: hidden;
    padding: 0.5em 0;
    box-shadow: 0px 4px 4px rgba(69, 99, 135, 0.08);

    display: grid;
    place-items: center;
  }
  .partners__logo-c:hover {
    box-shadow: 0px 4px 4px rgba(69, 99, 135, 0.11);
  }

  @media (min-width: 1100px) {
    & {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
`;
export const PartnersLogo = () => {
  return (
    <Partners>
          <figure>
            <figcaption>
              <h2 className="partners__heading">Our Partners</h2>
            </figcaption>
            <div className="partners__logos">
              <div className="partners__logo-c">
                <a
                  href="https://cybersecurity.att.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={alienVaultLogo}
                    alt="AlienVault logo"
                  />
                </a>
              </div>
              <div className="partners__logo-c">
                <a
                  href="https://www.barracuda.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={barracudaLogo}
                    alt="Barracudalogo"
                  />
                </a>
              </div>
              <div className="partners__logo-c">
                <a
                  href="https://bcinthecloud.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={BCInTheCloudLogo}
                    alt="BC in the Cloud logo"
                  />
                </a>
              </div>
              <div className="partners__logo-c">
                <a
                  href="https://www.cisco.com/c/en_dz/index.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={ciscoLogo}
                    alt="Cisco logo"
                  />
                </a>
              </div>
              <div className="partners__logo-c">
                <a
                  href="https://www.commvault.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={commVaultLogo}
                    alt="CommVault logo"
                  />
                </a>
              </div>
              <div className="partners__logo-c">
                <a
                  href="https://www.coreview.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={coreViewLogo}
                    alt="CoreView logo"
                  />
                </a>
              </div>
              <div className="partners__logo-c">
                <a href="https://www.dell.com" target="_blank" rel="noreferrer">
                  <img
                    className="partners__logo"
                    src={dellLogo}
                    alt="Dell logo"
                  />
                </a>
              </div>
              <div className="partners__logo-c">
                <a href="https://www.f5.com/" target="_blank" rel="noreferrer">
                  <img className="partners__logo" src={f5Logo} alt="f5 logo" />
                </a>
              </div>
              <div className="partners__logo-c">
                <a
                  href="https://www.fortinet.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={fortinetLogo}
                    alt="Fortinet logo"
                  />
                </a>
              </div>

              <div className="partners__logo-c">
                <a
                  href="https://www.forescout.com/company/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={forescoutLogo}
                    alt="Forescout logo"
                  />
                </a>
              </div>

              <div className="partners__logo-c">
                <a
                  href="https://www.hp.com/emea_africa-en/home.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img className="partners__logo" src={HPLogo} alt="HP Logo" />
                </a>
              </div>
              <div className="partners__logo-c">
                <a
                  href="https://www.microsoft.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={microsoftLogo}
                    alt="Microsoft logo"
                  />
                </a>
              </div>
              <div className="partners__logo-c">
                <a
                  href="https://www.netapp.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={netAppLogo}
                    alt="NetApp logo"
                  />
                </a>
              </div>

              <div className="partners__logo-c">
                <a
                  href="https://www.hpe.com/us/en/storage/nimble.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={nimbleStorageLogo}
                    alt="Nimble Storage logo"
                  />
                </a>
              </div>
              <div className="partners__logo-c">
                <a
                  href="https://www.sophos.com/en-us/company"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={sophosLogo}
                    alt="Sophos logo"
                  />
                </a>
              </div>

              <div className="partners__logo-c">
                <a
                  href="https://www.trendmicro.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={trendMicroLogo}
                    alt="Trend Micro logo"
                  />
                </a>
              </div>

              <div className="partners__logo-c">
                <a
                  href="https://www.veeam.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={veeamLogo}
                    alt="Veeam logo"
                  />
                </a>
              </div>

              <div className="partners__logo-c">
                <a
                  href="https://www.vmware.com/company.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="partners__logo"
                    src={VMWareLogo}
                    alt="VMWare logo"
                  />
                </a>
              </div>
            </div>
          </figure>
        </Partners>
  )
}
