import styled from "styled-components";
import React, { useState } from "react";
import Navbar from "../Navbar";
import Footer from "../Footer";

import { convertHexToRgba } from "../../utils/convertHexToRgba";

import heroBg from "../../assets/images/contact/hero-bg.png";
import phoneIcon from "../../assets/images/phoneIcon.png";
import EmailIcon from "../../assets/images/EmailIcon.png";
import locationIcon from "../../assets/images/locationIcon.png";
import hexagonsImg from "../../assets/images/contact/hexagons.png";

import provenExperienceImage from "../../assets/images/home/proven-experience-img.jpg";
import axios from "axios";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../Spinners/LoadingState";
const Header = styled.header`
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${heroBg});
  background-color: ${({ theme }) => theme.colors.mediumBlue};
  background-repeat: no-repeat;
  background-size: cover;
  color: ${({ theme }) => theme.colors.white};

  min-height: 555px;
  padding: 2em 5% 4em;

  display: grid;
  align-items: start;

  .hero {
    max-width: 582px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    text-align: center;
  }

  .hero__heading {
    font-size: 2.375rem;
    font-weight: 700;
    margin-bottom: 0.5em;
  }

  .hero__p {
    line-height: 1.5;
  }

  @media (min-width: 1100px) {
    & {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
`;

const Main = styled.main`
  color: ${({ theme }) => theme.colors.darkGray};
`;

const FormSection = styled.section`
  margin: 4em 5% 10em;

  .form-wrapper {
    overflow: hidden;

    display: flex;
    flex-direction: column;
    gap: 2em calc(2em + 7.5%);
  }

  .form,
  .address-wrapper {
    flex: 1;
  }

  .form {
    background: ${({ theme }) => theme.colors.white};

    max-width: 650px;
  }

  .form__inputs {
    display: grid;
    gap: 1.5em calc(1em + 10%);
  }

  .form__heading {
    font-size: 1.5rem;
    margin-bottom: 1em;
  }

  .form__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5em;

    &--textarea {
      grid-column: 1 / -1;
    }
  }

  .form__label {
    font-weight: 600;
  }

  .form__input {
    border: 1px solid #0e0b2b33;
    border-radius: 0.3125em;
    color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, 0.6)};
    font-size: 0.875rem;
    padding: 0.5em 1em;
    width: 100%;

    &--textarea {
      resize: none;
    }

    &:focus {
      outline: 2px solid ${({ theme }) => theme.colors.darkGray};
    }

    /* Chrome/Opera/Safari */
    &::-webkit-input-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, 0.6)};
    }

    /* Firefox 19+ */
    &::-moz-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, 0.6)};
    }

    /* IE 10+ */
    &:-ms-input-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, 0.6)};
    }

    /* Firefox 18- */
    &:-moz-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, 0.6)};
    }
  }

  .form__btn {
    background: ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.white};

    border: none;
    border-radius: 0.25em;
    cursor: pointer;
    display: block;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1.5em;
    // padding: .625em calc(.875em + 1.5%);
    padding: 0.5rem 3rem;
  }

  .address-wrapper {
    background: ${({ theme }) => theme.colors.mediumBlue};
    color: ${({ theme }) => theme.colors.white};

    border-radius: 1.25em;
    padding: 3em 2em;
    padding-bottom: 1em;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .address {
    font-weight: 600;
    line-height: 1.5;
  }
 
  .address__heading {
    font-size: 1.25rem;
    font-weight: 700;
    margin: 0.5em 0 1em;
  }
  .address-wrapper i {
    margin-right:0.5rem !important;
  }

  .address__link {
    color: inherit;
    display: block;
    margin: 1.5em 0;
  }

  .address-wrapper__img {
    align-self: flex-end;
  }

  @media (min-width: 800px) {
    .form-wrapper {
      flex-direction: row;
    }

    .form__inputs {
      grid-template-columns: repeat(2, 1fr);
    }

    .address-wrapper {
      max-width: 385px;
    }
  }

  @media (min-width: 1100px) {
    & {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
`;

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const toggleLoad = () => {
    setLoading(true);
  };
  const formSchema = yup.object().shape({
    fullname: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.number().required(),
    subject: yup.string().required(),
    message: yup.string().required(),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onsubmit = handleSubmit(async (val) => {
    // const {fullname, email, phone, subject, message} = val
    // console.log(val)
    const url = "https://freshstreams.co/gcet/api/gcet/contact";
    toggleLoad();
    await axios
      .post(url, val)
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: "Message sent successfully",
          icon: "success",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err.message);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        setLoading(false);
      });

    reset();
  });
  return (
    <>
      {loading ? <Loading /> : null}
      <Header>
        <Navbar />

        <section className="hero">
          <h1 className="hero__heading">Contact us</h1>
          <p className="hero__p">
            <abbr title="Global Concept Emerge Technologies">GCET</abbr> brings
            to the table a team of renowned professionals who have worked across
            all sectors of Nigeria's economy. We go the extra mile in providing
            customer-centric and comprehensive services that includes reliable
            Customer and Technical support with regular product updates. At
            Global Concept Emerge Technologies Limited, having built the
            necessary portfolio of technology partnerships, we combine the most
            effective ways to solve our customer's issues with the most secure
            and bespoke solutions.
          </p>
        </section>
      </Header>
      <Main>
        <FormSection>
          <div className="form-wrapper">
            <form className="form" onSubmit={onsubmit}>
              <h2 className="form__heading">Send us a Message</h2>

              <div className="form__inputs">
                <div className="form__input-wrapper">
                  <label className="form__label" htmlFor="name">
                    Your Name
                  </label>
                  <input
                    className="form__input"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Type Your Name Here"
                    {...register("fullname")}
                  />
                </div>
                <div className="form__input-wrapper">
                  <label className="form__label" htmlFor="email">
                    Email Address
                  </label>
                  <input
                    className="form__input"
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter a Valid Email Address"
                    {...register("email")}
                  />
                </div>
                <div className="form__input-wrapper">
                  <label className="form__label" htmlFor="phone-number">
                    Phone Number
                  </label>
                  <input
                    className="form__input"
                    type="tel"
                    id="phone-number"
                    name="phone-number"
                    placeholder="Phone Number"
                    {...register("phone")}
                  />
                </div>
                <div className="form__input-wrapper">
                  <label className="form__label" htmlFor="message-subject">
                    Message Subject
                  </label>
                  <input
                    className="form__input"
                    type="text"
                    id="message-subject"
                    name="message-subject"
                    placeholder="Type Here"
                    {...register("subject")}
                  />
                </div>
                <div className="form__input-wrapper form__input-wrapper--textarea">
                  <label className="form__label" htmlFor="message">
                    Message
                  </label>
                  <textarea
                    className="form__input form__input--textarea"
                    name="message"
                    id="message"
                    placeholder="Type Message Here..."
                    rows="5"
                    {...register("message")}
                  />
                </div>
              </div>
              <button className="form__btn" type="submit">
                Submit
              </button>
            </form>
            <div className="address-wrapper">
              <address className="address">
                <h3 className="address__heading">Contact Information</h3>
                
                <a className="address__link" href="mailto:info@gcetng.com">
                 <i class="fa-solid  fa-location-dot"></i> Global Concept Emerge <br /> Technologies Limited. <br /> 16, Amodu Ojikutu
                  Street, <br /> Victoria Island, Lagos.
                </a>
                <a className="address__link" href="mailto:info@gcetng.com">
                <i class="fa-solid  fa-envelope"></i> info@gcetng.com
                </a>
                <a className="address__link" href="tel:+234 8118193875">
                  {" "}
                  <i class="fa-solid  fa-phone"></i> +234 8118193875
                </a>
              </address>

              <img className="address-wrapper__img" src={hexagonsImg} alt="" />
            </div>
          </div>
        </FormSection>
      </Main>
      <Footer />
    </>
  );
};

export default ContactUs;
