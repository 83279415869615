import styled from 'styled-components';

import Navbar from '../../Navbar';
import Footer from '../../Footer';

import { convertHexToRgba } from '../../../utils/convertHexToRgba';

import NetworkAssessmentHeroBg from '../../../assets/images/services/network-assessment-hero-bg.jpg';
import NetworkAssessmentOverview from '../../../assets/images/services/network-assessment-overview.png';
import NetworkAssessment2 from '../../../assets/images/services/network-assessment-2.png';

import { PartnersLogo } from '../PartnersLogo';
import { ServicesFormRequest } from '../ServicesFormRequest';

const Header = styled.header`
    background-image: linear-gradient(${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, .7)}, ${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, .7)}), 
      url(${NetworkAssessmentHeroBg});
    background-repeat: no-repeat;
    background-size: cover;
    color: ${({ theme }) => theme.colors.white};

    min-height: min(100vh, 520px);
    padding: 2em 5%;

    display: grid;
    align-items: start;

    h1 {
      font-size: 2.5rem;
      font-weight: 700;
    }

    @media (min-width: 1100px) {
      & {
        padding-left: 120px;
        padding-right: 120px;
      }
    }
`;

const Main = styled.main`
    color: ${({ theme }) => theme.colors.darkGray};

    h2 {
        font-size: 2rem;
        font-weight: 700;
        margin: .5em 0;
    }

    p {
        line-height: 1.5625;
    }
`;
const Overview = styled.section`
  margin: 3em 5%;

  display: flex;
  flex-direction: column;
  gap: 2em calc(2em + 5vw);

  .overview__heading {
    color: ${({ theme }) => theme.colors.mediumBlue};
    font-size: 2rem;
    font-weight: 600;
  }
  .overview__list > * {
    list-style: disc inside;
    margin-bottom: 1.5em;

  }
  .overview__children {
    line-height: 2.1;
    padding-top: 1rem;

    > * {
      margin-bottom: 1.5em;
    line-height:2.1;

    }
  }
  
 

  @media (min-width: 800px) {
    & {
      flex-direction: row;
    }

    .overview__left,
    .overview__right, .overview__left-2 {
      flex: 1;
    }

    .overview__left,  {
      max-width: 440px;
      margin-top: 1rem;
    }
    .overview__left-2{
        max-width:700px;
    }
    .overview__left img{
      min-height:390px;
      border-radius:3px;
   }
    .overview__left-2 img {
      min-height: 650px !important;
      border-radius: 3px;
      margin-top:1rem;
    }

    .overview__right {
      max-width: 700px;
    }
  }

  @media (min-width: 1100px) {
    & {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
`;

const Overviewwww = styled.section`
  margin: 3em 5%;

  display: flex;
  flex-direction: column;
  gap: 2em calc(2em + 5vw);

  .overview__heading {

    color: ${({ theme }) => theme.colors.mediumBlue};
    font-size: 2rem;
    font-weight: 600;
   
  }

  .overview__children  {
    line-height: 2.1;

    > * {
      margin: 1.5em 0;
    }
  }

  .overview__children p{
    line-height: 2.1;

  }

  .overview__list {
    list-style: disc inside;

    display: flex;
    flex-direction: column;
    gap: 1em;
  }

  @media (min-width: 800px) {
    & {
      flex-direction: row;
    }
    
    .overview__left,
    .overview__right {
      flex: 1;
    }

    .overview__left {
      max-width: 440px;
    //   margin-top:1rem;
    }

    .overview__right {
      max-width: 700px;
    }
  }

  @media (min-width: 1100px) {
      & {
        margin-left: 120px;
        margin-right: 120px;
      }
    }
`;


const Request = styled.section`
    color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .9)};
    margin: 8em 5%;

    display: flex;
    flex-direction: column;
    gap: 2em;

    a {
        color: inherit;
    }

    .request__form {
        // background: #F9FFFF;
        border: 1px solid rgba(0, 0, 4, 0.1);
        border-radius: 2.5em;
        padding: 1.5em;

        display: grid;
        gap: 1.5em 1em;
    }

    .form__input-wrapper {
        display: flex;
        flex-direction: column;
        gap: .5em;
    }

    .form__label {
        font-weight: 700;
    }

    .form__select,
    .form__input {
        background: transparent;
        border: 1px solid rgba(14, 11, 43, 0.2);
        border-radius: .25em;
        font-size: .875rem;
        padding: .5em 1em;
        width: 100%;
        
        &:focus {
            outline: 2px solid ${({ theme }) => theme.colors.darkGray};
        }
    }

    .form__input {

        /* Chrome/Opera/Safari */
        &::-webkit-input-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }

        /* Firefox 19+ */
        &::-moz-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }

        /* IE 10+ */
        &:-ms-input-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }

        /* Firefox 18- */
        &:-moz-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }
    }

    .form__btn {
        background: ${({ theme }) => theme.colors.lightBlue};
        border: none;
        cursor:pointer;
        border-radius: .5em;
        color: ${({ theme }) => theme.colors.white};
        font-size: 1.125rem;
        margin-top: 1em;
        padding: .625em .875em;
    }
    .form__btn:hover {
        background: ${({ theme }) => theme.colors.darkGray};
        
    }
    @media (min-width: 700px) {
        & {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .request__text,
        .request__form {
            flex: 1;
        }
    

        .request__text {
            max-width: 500px;
        }

        .request__form {
            grid-template-columns: repeat(2, 1fr);
            max-width: 400px;
        }
       

        .form__input-wrapper:first-child,
        .form__btn {
            grid-column: 1 / -1;
        }
    }

    @media (min-width: 1100px) {
      & {
        margin-left: 120px;
        margin-right: 120px;
      }
    }
`;

const NetworkAssessment = () => {

    return (
        <>
            <Header>
                <Navbar />

                <h1 style={{'textAlign':'center'}}>Network Assessment Services <br />
                (NAMS)
                    </h1>
            </Header>
            <Main>
                <Overview>
                    <div className='overview__left' style={{marginTop:'1rem'}}>
                       
                        <img src={NetworkAssessmentOverview} alt="" />
                    </div>
                    <div className='overview__right'>
                    <h2 className='overview__heading'>Overview</h2>
                    <div className="overview__children">

                        <p>
                        Our consultation services provide an overlook on how we can help assess any issues 
                        with the current network. Based on the assumptions given, we can infer an adequate 
                        synopsis on what is best for the company IT infrastructure. Our recommendation will 
                        be a valuable asset in noticing even the most under looked procedures and aspects of 
                        the IT infrastructure in need of maintenance, risk assessment and future investment.
                        
   
                        </p>
                        <p>
                        We will produce the tasks needed to support the network assessment. The overall scope
                         will feature a generalization of the work that needs to be accomplished. The following
                          areas will have a deep dive session:

                        </p>
                
                    </div>


                    </div>
                </Overview>

                <Overview>
                    <div className="overview__left" >
                        <div className="overview__children">

                    <ul className='overview__list'>
                            <p>The following areas will have a deep dive session:</p>
                            <li>Physical Environment - Quite often overlooked is the space containing the network infrastructure.</li>
                            <li>Staff Engagement - The entire procedure will be interactive with ARM Technical, Control and user group in different sessions.</li>
                            <li>Network Infrastructure - Overall look at internet security, basic cabling, and network management.</li>
                            <li>Servers and Workstations - Provides a better understanding of what current technology is being used, what servers are supporting the work, server management, allocated disk space, software compatibility, and more.</li>
                            <li>Procedural Practices &amp; Documentation - To further rid of issues in the future, proper support is to be considered as a remedy to prevent faulty practicality</li>
                        </ul>
                        </div>

                    </div>

                    <div className="overview__left-2" style={{marginTop:'1rem'}}>
                    <img src={NetworkAssessment2} alt="" />

                    </div>


                </Overview>
                <ServicesFormRequest />
                <PartnersLogo />
            </Main>
            <Footer />
        </>
    )
}

export default NetworkAssessment;