import img1 from '../../assets/images/blog/naked-security.png'
import img2 from '../../assets/images/blog/chrome.png'
import img3 from '../../assets/images/blog/conti.png'
import img4 from '../../assets/images/blog/zoom.png'
import img5 from '../../assets/images/blog/naked-security2.png'
import img6 from '../../assets/images/blog/apicleak.png'
import img7 from '../../assets/images/blog/slack.png'
import img8 from '../../assets/images/blog/traffic.png'

export const HighlightData = [
    {
        img:img1, 
        author:'Paul Duckin',
        date:'18th Aug 2022',
        text:'S3 Ep96: Zoom 0-day, AEPIC leak, Conti reward, healthcare security [Audio + Text]',
        link:' https://nakedsecurity.sophos.com/2022/08/18/s3-ep96-zoom-0-day-aepic-leak-conti-reward-heathcare-security-audio-text/'
    },
    
    {
        img:img2, 
        author:'Paul Duckin',
        date:'17th Aug 2022',
        text:'Chrome browser gets 11 security fixes with 1 zero-day – update now!',
        link:'https://nakedsecurity.sophos.com/2022/08/17/chrome-browser-gets-11-security-fixes-with-1-zero-day-update-now/'
    },
    
    {
        img:img3, 
        author:'Naked Secret Writer',
        date:'16th Aug 2022',
        text:'US offers reward “up to $10 million” for information about the Conti gang',
        link:'https://nakedsecurity.sophos.com/2022/08/16/us-offers-reward-up-to-10-million-for-information-about-the-conti-gang/'
    },
    
    {
        img:img4, 
        author:'Paul Duckin',
        date:'15th Aug 2022',
        text:'Zoom for Mac patches critical bug – update now!',
        link:' https://nakedsecurity.sophos.com/2022/08/15/zoom-for-mac-patches-get-root-bug-update-now/'
    },
    
    {
        img:img5, 
        author:'Paul Duckin',
        date:'11th Aug 2022',
        text:'S3 Ep95: Slack leak, Github onslaught, and post-quantum crypto [Audio + Text]',
        link:' https://nakedsecurity.sophos.com/2022/08/11/s3-ep95-slack-leak-github-onslaught-and-post-quantum-crypto-audio-text/'
    },
    
    {
        img:img6, 
        author:'Paul Duckin',
        date:'10th Aug 2022',
        text:'APIC/EPIC! Intel chips leak secrets even the kernel shouldn’t see…',
        link:' https://nakedsecurity.sophos.com/2022/08/10/apic-epic-intel-chips-leak-secrets-even-the-kernel-shouldnt-see/'
    },
    
    {
        img:img7, 
        author:'Paul Duckin',
        date:'8th Aug 2022',
        text:'Slack admits to leaking hashed passwords for five years',
        link:' https://nakedsecurity.sophos.com/2022/08/08/slack-admits-to-leaking-hashed-passwords-for-three-months/'
    },
    
    {
        img:img8, 
        author:'Paul Duckin',
        date:'5th Aug 2022',
        text:'Traffic Light Protocol for cybersecurity responders gets a revamp',
        link:'https://nakedsecurity.sophos.com/2022/08/05/traffic-light-protocol-for-cybersecurity-responders-gets-a-revamp/'
    },
    
]