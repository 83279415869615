import styled from "styled-components";

import Navbar from "../../Navbar";
import Footer from "../../Footer";

import { convertHexToRgba } from "../../../utils/convertHexToRgba";

import ENSHeroBg from "../../../assets/images/solutions/ens-hero-bg.jpg";
import ENSOverview from "../../../assets/images/solutions/ens-overview.png";

// Partners' logo
import juniperNetworksLogo from "../../../assets/images/partners/juniper-networks.png";
import ciscoLogo from "../../../assets/images/partners/cisco.png";
import Barracuda from "../../../assets/images/partners/barracuda.png";
import f5 from "../../../assets/images/partners/f5.png";
import fortinet from "../../../assets/images/partners/fortinet.png";
import sophosLogo from "../../../assets/images/partners/sophos.png";

const Header = styled.header`
  background-image: linear-gradient(
      ${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, 0.7)},
      ${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, 0.7)}
    ),
    url(${ENSHeroBg});
  background-repeat: no-repeat;
  background-size: cover;
  color: ${({ theme }) => theme.colors.white};

  min-height: min(100vh, 520px);
  padding: 2em 5%;

  display: grid;
  align-items: start;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  @media (min-width: 1100px) {
    & {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
`;

const Main = styled.main`
  color: ${({ theme }) => theme.colors.darkGray};
`;

const Overview = styled.section`
  margin: 3em 5%;

  display: flex;
  flex-direction: column;
  gap: 2em calc(2em + 5vw);

  .overview__heading {
    color: ${({ theme }) => theme.colors.mediumBlue};
    font-size: 2rem;
    font-weight: 600;
  }

  .overview__children {
    line-height: 2.1;
    padding-top: 1rem;

    > * {
      margin-bottom: 1.5em;
    }
  }

  @media (min-width: 800px) {
    & {
      flex-direction: row;
    }

    .overview__left,
    .overview__right {
      flex: 1;
    }

    .overview__left {
      max-width: 440px;
      margin-top: 1rem;
    }

    .overview__left img {
      min-height: 450px;
      border-radius: 3px;
    }

    .overview__right {
      max-width: 700px;
    }
  }

  @media (min-width: 1100px) {
    & {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
`;

const Partners = styled.section`
  margin: 5em 5% 13em;

  .partners__heading {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  .partners__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5em;

    margin-top: 3em;
  }

  .partners__logo-c {
    flex: max(calc(25% - 2.5em), 160px);
    height: 100px;

    display: grid;
    place-items: center;
  }

  .partners__link {
    border-radius: 0.625em;
    box-shadow: 0px 4px 4px rgba(69, 99, 135, 0.08);
    display: block;
    overflow: hidden;
    height: 100%;
    width: 100%;
    max-width: 160px;
    padding: 0.75em;
  }

  .partners__link:hover {
    box-shadow: 0px 4px 4px rgba(69, 99, 135, 0.11);
  }
  .partners__logo {
    height: 100%;
    width: 100%;

    object-fit: scale-down;
  }

  @media (min-width: 1100px) {
    & {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
`;

const ENS = () => {
  return (
    <>
      <Header>
        <Navbar />

        <h1 style={{ textAlign: "center" }}>
          Enterprise Networking  Solution <br /> (ENS)
         
        </h1>
      </Header>
      <Main>
        <Overview>
          <div className="overview__left">
            {/* <h2 className='overview__heading'>Overview</h2> */}
            <img src={ENSOverview} alt="" />
          </div>
          <div className="overview__right">
            <h2 className="overview__heading">Overview</h2>

            <div className="overview__children">
              <p>
                Enterprise Networks Solutions help you accelerate innovation,
                reduce cost and complexity, and enhance security and benefits
                from mobility. This provides customers provides fast and
                reliable connectivity for end users as well as applications.
              </p>
              <p>
                Applications are increasingly more distributed in the modern
                network and simplified networking and security across wired and
                wireless infrastructure is a business imperative. Also helps
                employees and machines communicate, share files, access systems,
                and analyze the performance of an IT environment that drives
                business operations.
              </p>
              <p>
                Enterprise networks are configured to connect a limited number
                of authorized systems, apps, and individuals.
              </p>
            </div>
          </div>
        </Overview>
        <Partners>
          <h2 className="partners__heading">Our Partners</h2>
          <div className="partners__logos">
          <div className="partners__logo-c">
            <a
              className="partners__link"
              href="https://www.barracuda.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partners__logo"
                src={Barracuda}
                alt="Barracuda logo"
              />
            </a>
          </div>
          <div className="partners__logo-c">
              <a
                className="partners__link"
                href="https://www.cisco.com/c/en_dz/index.html"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="partners__logo"
                  src={ciscoLogo}
                  alt="Cisco logo"
                />
              </a>
            </div>
          <div className="partners__logo-c">
            <a
              className="partners__link"
              href="https://www.f5.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img className="partners__logo" src={f5} alt="F5 logo" />
            </a>
          </div>
          <div className="partners__logo-c">
            <a
              className="partners__link"
              href="https://www.fortinet.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="partners__logo"
                src={fortinet}
                alt="Fortinent Logo"
              />
            </a>
          </div>
         
            <div className="partners__logo-c">
              <a
                className="partners__link"
                href="https://www.juniper.net"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="partners__logo"
                  src={juniperNetworksLogo}
                  alt="Juniper Networks logo"
                />
              </a>
            </div>
          
            <div className="partners__logo-c">
              <a
                className="partners__link"
                href="https://www.sophos.com/en-us/company"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="partners__logo"
                  src={sophosLogo}
                  alt="Sophos logo"
                />
              </a>
            </div>
          </div>
        </Partners>
      </Main>
      <Footer />
    </>
  );
};

export default ENS;
