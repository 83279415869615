import styled from 'styled-components';

import Navbar from '../../Navbar';
import Footer from '../../Footer';

import { convertHexToRgba } from '../../../utils/convertHexToRgba';

import CMSHeroBg from '../../../assets/images/solutions/cms-hero-bg.jpg';
import CMSOverview from '../../../assets/images/solutions/cms-overview.jpg';
import CMSOverview2 from '../../../assets/images/solutions/cms-overview-2.jpg';

// Partners' logo
import AWSLogo from '../../../assets/images/partners/aws.png';
import azureLogo from '../../../assets/images/partners/azure.png';
import citrixLogo from '../../../assets/images/partners/citrix.png';
import manageEngineLogo from '../../../assets/images/partners/manage-engine.png';


const Header = styled.header`
    background-image: linear-gradient(${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, .7)}, ${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, .7)}), 
      url(${CMSHeroBg});
    background-repeat: no-repeat;
    background-size: cover;
    color: ${({ theme }) => theme.colors.white};

    min-height: min(100vh, 520px);
    padding: 2em 5%;

    display: grid;
    align-items: start;

    h1 {
      font-size: 2.5rem;
      font-weight: 700;
    }

    @media (min-width: 1100px) {
      & {
        padding-left: 120px;
        padding-right: 120px;
      }
    }
`;

const Main = styled.main`
  color: ${({ theme }) => theme.colors.darkGray};
`;

const Overview = styled.section`
  margin: 3em 5%;

  display: flex;
  flex-direction: column;
  gap: 2em calc(2em + 5vw);

  .overview__heading {

    color: ${({ theme }) => theme.colors.mediumBlue};
    font-size: 2rem;
    font-weight: 600;
 
  }

  .overview__children {
    line-height: 2.1;
    padding-top:1rem;


    > * {
      margin-bottom: 1.5em;
    }
  }

  @media (min-width: 800px) {
    & {
      flex-direction: row;
    }
    
    .overview__left,
    .overview__right {
      flex: 1;
    }

    .overview__left {
      max-width: 440px;
      margin-top:1rem;
    }
    .overview__left img{
      min-height:590px;
      border-radius:3px;
   }

    .overview__right {
      max-width: 700px;
    }
  }

  @media (min-width: 1100px) {
      & {
        margin-left: 120px;
        margin-right: 120px;
      }
    }
`;

const Partners = styled.section`
    margin: 5em 5% 13em;

    .partners__heading {
      font-size: 2rem;
      font-weight: 700;
      text-align: center;
    }

    .partners__logos {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2.5em;
      
      margin-top: 3em;
    }

    .partners__logo-c {
        flex: max(calc(25% - 2.5em), 160px);
        height: 100px;
        
        display: grid;
        place-items: center;
    }

    .partners__link {
      box-shadow: 0px 4px 4px rgba(69, 99, 135, 0.08);
      border-radius: .625em;
      
      display: block;
      overflow: hidden;
      height: 100%;
      width: 100%;
      max-width: 160px;
      padding: .75em;
    }
    .partners__link:hover {
      box-shadow: 0px 4px 4px rgba(69, 99, 135, 0.11);
 
    }
    
    .partners__logo {
      height: 100%;
      width: 100%;
      
      object-fit: scale-down;
    }

    @media (min-width: 1100px) {
      & {
        margin-left: 120px;
        margin-right: 120px;
      }
    }
`;

const CMS = () => {

  return (
    <>
      <Header>
        <Navbar />

        <h1 style={{'textAlign':'center'}}>
          Cloud and Mobile Solutions <br />
          (CMS)
          
        </h1>
      </Header>
      <Main>
        <Overview>
          <div className='overview__left'>
            {/* <h2 className='overview__heading'>Overview</h2> */}
            <img src={CMSOverview} alt="" />
          </div>
          <div className='overview__right'>
          <h2 className='overview__heading'>Overview</h2>
      <div className="overview__children">

          <p>
              We provide private cloud offerings for customers that have the supported Infrastructure in place and provide public cloud support
              for customers with offsite infrastructure needs.
            </p>
            <p>
              Under cloud solutions, we will provide self-service, service catalogues, automation and orchestration type of solutions using
              industry-leading technologies to achieve a flexible solution that will drive maximum customer benefits at very reasonable costs.
            </p>
            <p>
              In addition to these solutions, we will provide other supporting solutions like cloud backup and recovery as well as Cloud Disaster
              Recovery solutions and the link if necessary. With our Cloud Solutions &amp; Services, we help organizations enable IT-as-a-Service
              (ITaaS) (for internal or external use), using; to the extent possible, their existing infrastructure. Also we believe that
              improving on SLA's, added productivity via self-service and service analytics will enable our customers to derive maximum
              utilization from their IT investment.
            </p>
          </div>
    </div>

            </Overview>

            <Overview>
          <div className='overview__right'>
          {/* <h2 className='overview__heading'>Overview</h2> */}
            <div className='overview__children'>

          <p>
              We provide private cloud offerings for customers that have the supported Infrastructure in place and provide public cloud support
              for customers with offsite infrastructure needs.
            </p>
            <p>
              Under cloud solutions, we will provide self-service, service catalogues, automation and orchestration type of solutions using
              industry-leading technologies to achieve a flexible solution that will drive maximum customer benefits at very reasonable costs.
            </p>
            <p>
              In addition to these solutions, we will provide other supporting solutions like cloud backup and recovery as well as Cloud Disaster
              Recovery solutions and the link if necessary. With our Cloud Solutions &amp; Services, we help organizations enable IT-as-a-Service
              (ITaaS) (for internal or external use), using; to the extent possible, their existing infrastructure. Also we believe that
              improving on SLA's, added productivity via self-service and service analytics will enable our customers to derive maximum
              utilization from their IT investment.
            </p>
          </div>
          </div>


          
          <div className='overview__left'>
          
            <img src={CMSOverview} alt="" />
          </div>
            
          
        </Overview>
        <Partners>
          <h2 className='partners__heading'>Our Partners</h2>

          <div className="partners__logos">
            <div className="partners__logo-c">
              <a className='partners__link' href='https://aws.amazon.com' target='_blank' rel='noreferrer'>
                <img className='partners__logo' src={AWSLogo} alt="AWS logo" />
              </a>
            </div>
            <div className="partners__logo-c">
              <a className='partners__link' href='https://azure.microsoft.com' target='_blank' rel='noreferrer'>
                <img className='partners__logo' src={azureLogo} alt="Azure logo" />
              </a>
            </div>
            <div className="partners__logo-c">
              <a className='partners__link' href="https://www.citrix.com" target='_blank' rel='noreferrer'>
                <img className='partners__logo' src={citrixLogo} alt="Citrix logo" />
              </a>
            </div>
            <div className="partners__logo-c">
              <a className='partners__link' href='https://www.manageengine.com' target='_blank' rel='noreferrer'>
                <img className='partners__logo' src={manageEngineLogo} alt="Manage Engine logo" />
              </a>
            </div>
          </div>
        </Partners>
      </Main>
      <Footer />
    </>
  )
}

export default CMS;