
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
recentuser : null
}

const State = createSlice({
  name: "second",
  initialState,
  reducers: {
 signinuser :(state,{payload})=>{
             state.recentuser=payload
 }
  }
});

export const { signinuser} = State.actions

export default State.reducer