import { Link, Navigate } from "react-router-dom";
import React, { useState } from "react";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import { convertHexToRgba } from "../../utils/convertHexToRgba";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Loading from "../Spinners/LoadingState";
import axios from "axios";
import Swal from "sweetalert2";

const Request = styled.section`
  color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, 0.9)};
  margin: 8em 5%;

  display: flex;
  flex-direction: column;
  gap: 2em;

  a {
    color: inherit;
  }

  .request__form {
    // background: #F9FFFF;
    border: 1px solid rgba(0, 0, 4, 0.1);
    border-radius: 2.5em;
    padding: 1.5em;

    display: grid;
    gap: 1.5em 1em;
  }

  .form__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .form__label {
    font-weight: 700;
  }

  .form__select,
  .form__input {
    background: transparent;
    border: 1px solid rgba(14, 11, 43, 0.2);
    border-radius: 0.25em;
    font-size: 0.875rem;
    padding: 0.5em 1em;
    width: 100%;

    &:focus {
      outline: 2px solid ${({ theme }) => theme.colors.darkGray};
    }
  }

  .form__input {
    /* Chrome/Opera/Safari */
    &::-webkit-input-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.black, 0.2)};
    }

    /* Firefox 19+ */
    &::-moz-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.black, 0.2)};
    }

    /* IE 10+ */
    &:-ms-input-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.black, 0.2)};
    }

    /* Firefox 18- */
    &:-moz-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.black, 0.2)};
    }
  }

  .form__btn {
    background: ${({ theme }) => theme.colors.lightBlue};
    border: none;
    cursor: pointer;
    border-radius: 0.5em;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.125rem;
    margin-top: 1em;
    padding: 0.625em 0.875em;
  }
  .form__btn:hover {
    background: ${({ theme }) => theme.colors.darkGray};
  }
  @media (min-width: 700px) {
    & {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .request__text,
    .request__form {
      flex: 1;
    }

    .request__text {
      max-width: 500px;
    }

    .request__form {
      grid-template-columns: repeat(2, 1fr);
      max-width: 400px;
    }

    .form__input-wrapper:first-child,
    .form__btn {
      grid-column: 1 / -1;
    }
  }

  @media (min-width: 1100px) {
    & {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
`;

export const ServicesFormRequest = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const toggleLoad = () => {
    setLoading(true);
  };
  const formSchema = yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.number().required(),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const [select, setSelect] = useState("");
  const onsubmit = handleSubmit(async (val) => {
    const url = "https://freshstreams.co/gcet/api/gcet/discuss";
    toggleLoad();
    const { phone, firstname, lastname, email } = val;
    await axios
      .post(url, { phone, firstname, lastname, email, discussion: select })
      .then((res) => {
        console.log(res);
        Swal.fire({
          title: "Message sent successfully",
          icon: "success",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showConfirmButton: false,
          timer: 1500,
        });
        setLoading(false);

        // navigate("/");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
        setLoading(false);
      });
    reset()
  });

  return (
    <>
      {loading ? <Loading /> : null}

      <Request id="request">
        <div className="request__text">
          <h2>Request for a Call Back</h2>
          <p>
            We are available through our open channels to review your enquiry
            and respond at the shortest possible time. Kindly fill the call back
            form to receive a call from one of our experts, or chat with us
            online. You can as well send us an email on{" "}
            <a href="mailto:info@gcetng.com">info@gcetng.com</a> and you will
            receive our response within 24 hours.
          </p>
        </div>
        <form className="request__form" onSubmit={onsubmit}>
          <div className="form__input-wrapper">
            <label className="form__label" htmlFor="discussion-type">
              Discussion with Support Team
            </label>
            <select
              className="form__select"
              name="discussion-type"
              id="discussion-type"
              required
              value={select}
              onChange={(e) => setSelect(e.target.value)}
            >
              <option
                value=" Enterprise Security Solutions &amp; Services"
             
              >
                Enterprise Security Solutions &amp; Services
              </option>
              <option
                value=" Infrastructure Solutions &amp; Services "
              
              >
                Infrastructure Solutions &amp; Services
              </option>
              <option
                value=" Business Service Management Solutions "
                
              >
                Business Service Management Solutions
              </option>
              <option
                value=" Enterprise Networking Solutions "
              
              >
                Enterprise Networking Solutions
              </option>
              <option
                value=" Cloud and Mobile Solutions "
                
              >
                Cloud and Mobile Solutions
              </option>
              <option
                value=" Business Enterprise Software "
               
              >
                Business Enterprise Software
              </option>
              <option
                value=" IT Advisory &amp; Managed Services "
               
              >
                IT Advisory &amp; Managed Services
              </option>
              <option
                value=" Network Assessment Services "
                
              >
                Network Assessment Services
              </option>
            </select>
          </div>
          <div className="form__input-wrapper">
            <label className="form__label" htmlFor="first-name">
              First Name
            </label>
            <input
              className="form__input"
              id="first-name"
              type="text"
              name="first-name"
              placeholder="Type Here"
              {...register("firstname")}
            />
          </div>
          <div className="form__input-wrapper">
            <label className="form__label" htmlFor="last-name">
              Last Name
            </label>
            <input
              className="form__input"
              id="last-name"
              type="text"
              name="last-name"
              placeholder="Type Here"
              {...register("lastname")}
            />
          </div>
          <div className="form__input-wrapper">
            <label className="form__label" htmlFor="email">
              Email
            </label>
            <input
              className="form__input"
              id="email"
              type="email"
              name="email"
              placeholder="Sample@xyz.com"
              {...register("email")}
            />
          </div>
          <div className="form__input-wrapper">
            <label className="form__label" htmlFor="phone-number">
              Phone Number
            </label>
            <input
              className="form__input"
              id="phone-number"
              type="tel"
              name="phone-number"
              placeholder="012345678"
              {...register("phone")}
            />
          </div>
          {!loading && <button className="form__btn" type="submit">
            Submit
          </button>}
          {
            loading && <button disabled className="form__btn" type="submit">
            Submitting...
          </button>
          }
          
        </form>
      </Request>
    </>
  );
};
