import styled from "styled-components";

import Navbar from "../../Navbar";
import Footer from "../../Footer";

import { convertHexToRgba } from "../../../utils/convertHexToRgba";

import CMSHeroBg from "../../../assets/images/solutions/cms-hero-bg.jpg";
import CMSOverview from "../../../assets/images/solutions/cms-overview.jpg";
import CMSOverview2 from "../../../assets/images/solutions/cms-overview-2.jpg";

// Partners' logo
import InfiniteBlue from "../../../assets/images/partners/infinite-blue.png";
import Barracuda from "../../../assets/images/partners/barracuda.png";
import f5 from "../../../assets/images/partners/f5.png";
import fortinet from "../../../assets/images/partners/fortinet.png";
import ers1 from "../../../assets/images/solutions/ers-1.png";
import ersHero from "../../../assets/images/solutions/ersHero.png";
import ers2 from "../../../assets/images/solutions/ers-2.png";

const Header = styled.header`
  background-image: linear-gradient(
      ${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, 0.7)},
      ${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, 0.7)}
    ),
    url(${ersHero});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  color: ${({ theme }) => theme.colors.white};

  min-height: min(100vh, 520px);
  padding: 2em 5%;

  display: grid;
  align-items: start;

  h1 {
    font-size: 2.5rem;
    font-weight: 700;
  }

  @media (min-width: 1100px) {
    & {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
`;

const Main = styled.main`
  color: ${({ theme }) => theme.colors.darkGray};
`;

const Overview = styled.section`
  margin: 3em 5%;

  display: flex;
  flex-direction: column;
  gap: 2em calc(2em + 5vw);

  .overview__heading {
    color: ${({ theme }) => theme.colors.mediumBlue};
    font-size: 2rem;
    font-weight: 600;
  }
  .overview__list {
    list-style: disc inside;
  }
  .overview__children {
    line-height: 2.1;
    padding-top: 1rem;

    > * {
      margin-bottom: 1.5em;
    }
  }

  @media (min-width: 800px) {
    & {
      flex-direction: row;
    }

    .overview__left,
    .overview__right, .overview__left-2 {
      flex: 1;
    }

    .overview__left, .overview__left-2 {
      max-width: 440px;
      margin-top: 1rem;
    }
    .overview__left img{
      min-height:630px;
      border-radius:3px;
   }
    .overview__left-2 img {
      min-height: 430px !important;
      border-radius: 3px;
    }

    .overview__right {
      max-width: 700px;
    }
  }

  @media (min-width: 1100px) {
    & {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
`;

const Partners = styled.section`
  margin: 5em 5% 13em;

  .partners__heading {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
  }

  .partners__logos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2.5em;

    margin-top: 3em;
  }

  .partners__logo-c {
    flex: max(calc(25% - 2.5em), 160px);
    height: 100px;

    display: grid;
    place-items: center;
  }

  .partners__link {
    box-shadow: 0px 4px 4px rgba(69, 99, 135, 0.08);
    border-radius: 0.625em;

    display: block;
    overflow: hidden;
    height: 100%;
    width: 100%;
    max-width: 160px;
    padding: 0.75em;
  }
  .partners__link:hover {
    box-shadow: 0px 4px 4px rgba(69, 99, 135, 0.11);
  }

  .partners__logo {
    height: 100%;
    width: 100%;

    object-fit: scale-down;
  }

  @media (min-width: 1100px) {
    & {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
`;

const ERS = () => {
  return (
    <>
      <Header>
        <Navbar />

        <h1 style={{ textAlign: "center" }}>
          Enterprise Resilience Solution <br /> (ERS) 
        </h1>
      </Header>
      <Main>
        <Overview>
          <div className="overview__left">
            <div className="">
            <img  src={ers1} alt="" />

            </div>
          </div>
          <div className="overview__right">
            <h2 className="overview__heading">Overview</h2>
            <div className="overview__children">
              <p>
                {" "}
                A business continuity plan (BCP) is a system of prevention and
                recovery from potential threats to a company. The capability of
                an organization to continue the delivery of products or services
                at pre-defined acceptable levels following a disruptive
                incident.
              </p>

              <ul className="overview__list">
                <p>
                  The organization’s senior management team is responsible for
                  overseeing the business continuity planning process, which
                  includes:
                </p>
                <li>
                  Establishing policy by determining how the institution will
                  manage and control identified risks;
                </li>
                <li>
                  Allocating knowledgeable personnel and sufficient financial
                  resources to properly implement the BCP;
                </li>

                <li>
                  Ensuring that the BCP is reviewed and approved at least
                  annually;
                </li>
                <li>
                  Ensuring employees are trained and aware of their roles in the
                  implementation of the BCP;
                </li>
                <li>Ensuring the BCP is regularly tested</li>
                <li>
                  Reviewing the BCP testing program and test results on a
                  regular basis
                </li>
                <li>
                  Ensuring the BCP is continually updated to reflect the current
                  operating environment.
                </li>
              </ul>
            </div>
          </div>
        </Overview>

        <Overview>
          <div className="overview__right">
            {/* <h2 className="overview__heading">Overview</h2> */}
            <div className="overview__children">
              <p>
                The BC in the Cloud application provides an automated solution
                for building and maintaining an effective business continuity
                and disaster recovery program. With BC in the Cloud,
                organizations can mitigate risks, assess impact, and adhere to
                deadlines. As your BC/DR needs grow, the application can evolve
                with you. No other solution offers robust scalability and
                adaptability to meet your specific needs. Streamlining your
                business continuity, resiliency, and risk management programs is
                achievable with our comprehensive solution.
              </p>
              <p>
                Go above and beyond incident planning and response. Intelligent
                enterprise resilience solutions give you the power to foresee
                risks, predict impacts, collaborate, communicate, and emerge
                stronger than ever before.
              </p>
              
            </div>
          </div>

          <div className="overview__left-2">
            <img src={ers2}  alt="" />
          </div>
        </Overview>
        <Partners>
          <h2 className="partners__heading">Our Partner</h2>

          <div className="partners__logos">
            <div className="partners__logo-c">
              <a
                className="partners__link"
                href="https://infiniteblue.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="partners__logo" src={InfiniteBlue} alt="Infinite Blue logo" />
              </a>
            </div>
          
          </div>
        </Partners>
      </Main>
      <Footer />
    </>
  );
};

export default ERS;
