import styled from 'styled-components';
import React, {useEffect, useState} from "react"
import { HighlightData } from '../blog/HighlightData';
import Navbar from '../Navbar';
import Footer from '../Footer';
import Article from '../blog/Article';
// import SideArticle from '../blog/SideArticle';

import { convertHexToRgba } from '../../utils/convertHexToRgba';
import axios from 'axios';
import moment from "moment"

import articleImg1 from '../../assets/images/blog/article-1.jpg';
import articleImg2 from '../../assets/images/blog/article-2.jpg';
import articleImg3 from '../../assets/images/blog/article-3.jpg';
import articleImg4 from '../../assets/images/blog/article-4.jpg';
import articleImg5 from '../../assets/images/blog/article-5.jpg';
import articleImg6 from '../../assets/images/blog/article-6.jpg';
import articleImg7 from '../../assets/images/blog/article-7.jpg';
import articleImg8 from '../../assets/images/blog/article-8.jpg';
import articleImg9 from '../../assets/images/blog/article-9.jpg';
import articleImg10 from '../../assets/images/blog/article-10.jpg';

import heroBg from '../../assets/images/consultancy/hero-bg.png';
import imgBlog from '../../assets/images/blog/img-blog.png'


const Header = styled.header`
background-image: linear-gradient(${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, .7)}, ${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, .7)}), 
url(${imgBlog});
background-repeat: no-repeat;
background-size: cover;
color: ${({ theme }) => theme.colors.white};

min-height: min(100vh, 520px);
padding: 2em 5%;

display: grid;
align-items: start;

h1 {
font-size: 2.5rem;
font-weight: 700;
}

@media (min-width: 1100px) {
& {
  padding-left: 120px;
  padding-right: 120px;
}
}
`;

const Main = styled.main`
    color: ${({ theme }) => theme.colors.darkGray};
`;

const Intro = styled.section`
    margin: 3em 5%;
    text-align: center;

    .intro__heading {
        font-size: 2.75rem;
        font-weight: 700;
        margin: .125em 0;
    }

    .intro__form {
        border-radius: .625em;
        // box-shadow: 2px 3px 10px 2px rgba(182, 182, 182, 0.25);
        margin: 1.5em auto;
        max-width: 610px;
        padding: 1em;
        background: #fff;

        display: flex;
        flex-wrap: wrap;
        gap: 1em;
    }

    .intro__input {
        flex: 200px;

        border-radius: .3125em;
        background: #E8F1F5;
        border: none;
        outline: none;
        font-size: 1rem;
        padding: .75em;
        width: 100%;

        /* Chrome/Opera/Safari */
        &::-webkit-input-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }

        /* Firefox 19+ */
        &::-moz-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }

        /* IE 10+ */
        &:-ms-input-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }

        /* Firefox 18- */
        &:-moz-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }
    }

    .intro__btn {
        border: none;
        border-radius: .25em;
        background: ${({ theme }) => theme.colors.lightBlue};
        color: ${({ theme }) => theme.colors.white};
        font-size: 1rem;
        font-weight: 700;
        padding: .625em .875em;
    }
`; 

const ArticlesContainer = styled.div`
    margin: 4em 5%;

    display: grid;
    // grid-template-columns: 6fr 6fr;
    gap: calc(2em + 5vw);
    
    .articles {
        display: flex;
        flex-direction: column;
        gap: 2em;
    }

    .aside {
        display: flex;
        flex-direction: column;
        gap: 1.5em;
    }
    
    @media (min-width: 900px) {
        & {
            grid-template-columns: 1fr 390px;
        }
    }

    @media (min-width: 1100px) {
        & {
            margin-left: 120px;
            margin-right: 120px;
        }
    }
`;

const Blog = () => {
    const [getData, setGetData] = useState([])
    const [searchword, setSearchword] = useState("");
    const getall =async()=>{
        const url = "https://freshstreams.co/gcet/api/gcet/allblogs"
        await axios.get(url).then((res)=>{
            console.log(res.data.data)
            setGetData(res.data.data)
        }).catch((err)=>{
            console.log(err)
        })
    }
    const filtered = getData.filter((datas) => {
        return datas.description.toLowerCase().includes(searchword.toLowerCase());
      });
    
    useEffect(()=>{
getall()
    }, [])
    return (
        <>
            <Header>
                <Navbar />
                <Intro>
                    <h1 className='intro__heading'>Get Informed!</h1>
                    <p>Read the latest news here on our platform</p>
                    <form className='intro__form'>
                        <input className='intro__input' type="search" name="search" id="search" placeholder='Search for keywords or trends' 
                        onChange={(e)=>{
                            setSearchword(e.target.value)
                        }}
                        required />
                        <button className='intro__btn' type="submit">Search</button>
                    </form>
                </Intro>
            </Header>
            <Main>
             
                <ArticlesContainer>
                    <section className='articles'>
                        <Article image={articleImg1}
                            author='Paul Ducklin'
                            title='Laptop denial-of-service via music: the 1980s R&amp;B song with a CVE!'
                            link='https://nakedsecurity.sophos.com/2022/08/22/laptop-denial-of-service-via-music-the-1980s-rb-song-with-a-cve/'
                            date='2022-08-22'
                        >
                            <p>You've probably heard the old joke: “Humour in the public service? It's no laughing matter!”</p>
                            <p>But the thing with downbeat, blanket judgements of this sort is that it only takes a single counter-example to disprove"</p>
                        </Article>

                        <Article image={articleImg2}
                            author='Paul Ducklin'
                            title='Apple patches double zero-day in browser and kernel - update now!'
                            link='https://nakedsecurity.sophos.com/2022/08/18/apple-patches-double-zero-day-in-browser-and-kernel-update-now/'
                            date='2022-08-18'
                        >
                            <p>Apple just pushed out an emergency update for two zero-day bugs that are apparently actively being exploited.</p>
                            <p>There's a remote code execution hole (RCE) dubbed CVE-2022-32893 in Apple's HTML rendering software (WebKit), by means of which a booby trapped web page can trick iPhones, iPads and Macs into running unauthorised and untrusted software code</p>
                        </Article>
                    </section>
                    <aside className='aside'>
                        {HighlightData?.map((props)=>(
                            <Sarticle>
                <figure className='article__fig'>
                    <img className='article__img' src={props.img} alt="" />
                    <figcaption>
                        <div className="article__info">
                            <address className='article__author'>{props.author}</address>
                            <time className='article__time' >
                            {props.date}
                            </time>
                        </div>
                        <h3 className='article__heading'><a className='article__link' href={props.link} target='_blank' rel="noreferrer">{props.text}</a></h3>
                    </figcaption>
                </figure>
            </Sarticle>
                        ))}
                    
                       
                    </aside>
                </ArticlesContainer>
            </Main>
            <Footer />
        </>
    )
}
export default Blog
const Sarticle = styled.article`
line-height: 1.4;
/* background-color: red; */
.article__img {
   width: 100px;
   height: 107px;
}

.article__fig {
   display: flex;
   flex-direction: column;
   gap: 1em;
}

.article__info {
   display: flex;
   flex-wrap: wrap;
   align-items: center;
   gap: .5em;

   margin: .5em 0;
}

.article__author {
   background: ${({ theme }) => theme.colors.mediumBlue};
   color: #FFFFFC;
   font-size: .875em;
   font-weight: 600;
   border-radius: .1875em;
   max-width: fit-content;
   padding: .25em 1em;
   text-align: center;
}

.article__time {
   color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .8)};
   font-size: .875em;
}

.article__heading {
   font-size: 1.125rem;
   font-weight: 600;
   margin: .25em 0;
}

.article__link {
   color: inherit;
}

@media (min-width: 300px) {
   .article__fig {
       flex-direction: row;
   }
}
`;