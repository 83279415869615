import { useState, useEffect } from 'react';

import { Link, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; 

import { convertHexToRgba } from '../../utils/convertHexToRgba';
import Swal from "sweetalert2"
import logo from '../../assets/images/logo-header.png';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import Loading from "../Spinners/LoadingState"
// import { allblogs } from './Globalstate/State';

// const getFileSize = (size) => {
//     if (size < 1024) {
//         return `${size} bytes`;
//     } else if (size < 1024 ** 2) {
//         return `${(size / 1024).toFixed(2)}KB`;
//     } else {
//         return `${(size / (1024 ** 2)).toFixed(2)}MB`;
//     }
// }

const Header = styled.header`
    background: ${({ theme }) => theme.colors.mediumBlue};

    padding: 1em 5%;

    .header__logo {
        width: 100px;
    }

    @media (min-width: 1100px) {
      & {
        padding-left: 120px;
        padding-right: 120px;
      }
    }
`;

const FormSection = styled.section`

    margin: 0 auto;
    padding: 3em 5%;
    max-width: 1140px;

    .form-section__heading {
        font-size: 2.5rem;
        margin: .5em 0;
        text-align: center;
    }

    .form__inputs {
        margin: 3em 0;

        display: grid;
        gap: 1.5em calc(1em + 5%);
    }

    .form__input-wrapper {
        display: flex;
        flex-direction: column;
        gap: .625em;

    }

    .form__label {
        font-size: 1.125rem;
        font-weight: 600;
    }

    .form__input,
    .form__label--file-btn {
        border: 1px solid ${({ theme }) => theme.colors.darkGray};
        border-radius: .625em;
        font-size: 1rem;
        padding: .8125em 1.25em;
        width: 100%;
    }
    
    .form__input {
        
        /* Chrome/Opera/Safari */
        &::-webkit-input-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .7)};
        }

        /* Firefox 19+ */
        &::-moz-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .7)};
        }

        /* IE 10+ */
        &:-ms-input-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .7)};
        }
        
        /* Firefox 18- */
        &:-moz-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .7)};
        }
    }

    .form__input--file {
        height: .1px;
        width: .1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;


        :hover ~ .form__label--file-btn,
        :focus ~ .form__label--file-btn {
            outline: 1px solid ${({ theme }) => theme.colors.darkGray};
        }
    }

    .form__label--file-btn {
         background: var(--white);
        color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .7)};
        cursor: pointer;
        
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
        
    }

    .file-output__span {
        font-weight: 600;
    }
    
    .form__btn {
        background: ${({ theme }) => theme.colors.lightBlue};
        border: none;
        border-radius: .5em;
        color: ${({ theme }) => theme.colors.white};
        cursor: pointer;
        display: block;
        font-size: 1.25rem;
        font-weight: 700;
        margin: 0 auto;
        max-width: 560px;
        padding: .5em .625em;
        width: 100%;
    }

    @media (min-width: 700px) {
        .form__inputs {
            grid-template-columns: repeat(2, 1fr);
            justify-content: space-between;
        }

        .form__input-wrapper:last-child {
            grid-column: 1 / -1;
        }
    }
    
`;

const Blogs = styled.div`
    margin: 2em 5%;
   
    
    .blogs__list {
        margin: 0 auto;
        max-width: 1140px;

        display: flex;
        flex-direction: column;
        gap: 2em;
    }
    
    .article {
        background: rgba(0, 0, 0, 0.05);
        border-radius: .3125em;
        /* width: 100%; */
        
        padding: calc(1em + 1vw);
        display: flex;
        flex-direction: column;
        gap: 1.25em 2em;
    }
    
    .article__text {
        max-width: 610px;
    }
    .article__heading {
        font-size: 1.125rem;
        font-weight: 600;
        margin-bottom: .75em;
    }
    
    .article__p {
        line-height: 1.5625;
        
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .article__menu {
        display: flex;
        align-items: center;
        gap: 1.5em;
    }
    .article__link {
        background: ${({ theme }) => theme.colors.lightBlue};
        border-radius: .3125em;
        color: ${({ theme }) => theme.colors.white};
        font-size: 1.25rem;
        font-weight: 700;
        padding: .5em clamp(.5em, 4vw, 2.75em);
    }
    .article__delete-btn {
        background: transparent;
        border: none;
        cursor: pointer;
        color: #ff0000;
        font-size: 1rem;
        display: flex;
        align-items: center;
        gap: .75em;
    }
    @media (min-width: 700px) {
        .article {
            flex-direction: row;
            justify-content: space-between;
        }
        .article__menu {
            gap: calc(1.5em + 4vw);
        }
    }
    `;

const AddBlog = () => {
// const {id} = useParams()
// console.log(id) 
// const blog = useSelector((state)=>state.allblogs)
// console.log(blog)
const [loading, setLoading] =useState(false)
const toggleLoad =()=>{
    setLoading(true)
}
    const user = useSelector((state)=>state.recentuser)
    // console.log(user)
    const dispatch = useDispatch()
    // console.log(user.token)
const [getData, setGetData] = useState([])
const [base64code , setBase64code]= useState("")
const [title, setTitle]=useState("")
const [datee, setDatee] = useState("")
const [writerName, setWriterName]=useState("")
const [link, setLink]=useState("")
// const formSchema = yup.object().shape({
//     title:yup.string().required(),
//     description:yup.string().required(),
//     writerName:yup.string().email().required(),
//     link:yup.number().required()
  
// })

// const {register, reset, handleSubmit, formState:{errors}}= useForm({
//     resolver:yupResolver(formSchema)
// })
    const onChange=(e)=>{
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload=()=>{
            onload(reader.result)
        }
        reader.onerror=(error)=>{
            console.log("error", error)

        }
    }

    const onload = (fileString)=>{
        setBase64code(fileString)
    }

    const removeData = (id)=>{
        const url = `https://freshstreams.co/gcet/api/gcet/deleteblog/${id}`
        const config={
            headers:{
                authorization : `dee ${user.token}`
            }
        }
        axios.delete(url, config).then(()=>{
            // console.log("deleted")
            window.location.reload()
        }).catch((err)=>{
            console.log(err)
        })
    }
    console.log(user)
    const postData = async()=>{
        const url = `https://freshstreams.co/gcet/api/gcet/createBlog/${user.info._id}`
        const config={
            headers:{
                authorization : `dee ${user.token}`
            }
        }
        toggleLoad()
       await axios.post(url, {imagez:base64code, title:title, writerName:writerName, description:datee, link:link}, config ).then((res)=>{
            console.log(res)
            Swal.fire({
                title: 'Blog created successfully',
                icon: 'success',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp'
                },
                showConfirmButton: false,
                timer: 1500
              })
          setLoading(false);
        }).catch((error)=>{
            console.log(error)
        })
        window.location.reload()
    }
    const [formDetails, setFormDetails] = useState({
        name: '',
        date: '',
        title: '',
        link: ''
    });
    const [file, setFile] = useState(null);

    const handleInputChange = ({ target }) => {
        setFormDetails(prevState => ({ ...prevState, [target.name]: target.value }));
    }

    const handleFileChange = ({ target }) => {
        setFile(target.files[0]);
    }


    const getFileSize = (size) => {
        if (size < 1024) {
            return `${size} bytes`;
        } else if (size < 1024 ** 2) {
            return `${(size / 1024).toFixed(2)}KB`;
        } else {
            return `${(size / (1024 ** 2)).toFixed(2)}MB`;
        }
    }

    const getBlogs= async()=>{

        const url = "https://freshstreams.co/gcet/api/gcet/allblogs"
        await axios.get(url).then((res)=>{
            console.log(res.data.data)
            setGetData(res.data.data)
            // dispatch(allblogs(res.data.data))
        })
    }
    // console.log(blog)
    useEffect(() => {
      getBlogs()
    }, [])
    

    return (
        <>
        {loading ? <Loading /> : null}

            <Header>
                <Link to='/'>
                    <img className='header__logo' src={logo} alt="GCET logo" />
                </Link>
            </Header>
            <main>
                <FormSection>

                    <h1 className='form-section__heading'>Blog</h1>

                    <form >
                        <div className="form__inputs">
                            <div className="form__input-wrapper">
                                <label className='form__label' htmlFor="writer-name">Writer's Name</label>
                                <input className='form__input'
                                    type="text"
                                    id='writer-name'
                                    name='name'
                                    placeholder='Insert name'
                                   onChange={(e)=>{
                                    setWriterName(e.target.value)
                                   }}
                                    required
                                />
                            </div>
                            <div className="form__input-wrapper">
                                <label className='form__label' htmlFor="writer-name">Brief Discription</label>
                                <input className='form__input'
                                  
                                    id='date'
                                    name='date'
                                  
                                    required
                                    onChange={(e)=>{
                                        setDatee(e.target.value)
                                    }}
                                />
                            </div>
                            <div className="form__input-wrapper">
                                <label htmlFor="file-input" className="form__label">Insert image</label>
                                <input
                                    id="file-input"
                                    type="file"
                                    className="form__input form__input--file"
                                    name="image"
                                    accept='image/*'
                                    
                                    required
                                    onChange={onChange}
                                />
                                <label htmlFor="file-input" className="form__label--file-btn">
                                    Upload image
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-up-from-bracket" />
                                </label>

                                {file?.name &&
                                    (<output id="file-details" htmlFor="file-input">
                                        <small>
                                            <span className="file-output__span">File details</span>: {file.name} - {getFileSize(file.size)}
                                        </small> </output>)}
                            </div>
                            <div className="form__input-wrapper">
                                <label className='form__label' htmlFor="blog-title">Blog Title</label>
                                <input className='form__input'
                                    type="text"
                                    id='blog-title'
                                    name='title'
                                    placeholder='Insert title'
                                  onChange={(e)=>{
                                    setTitle(e.target.value)
                                  }}
                                    required
                                />
                            </div>
                            <div className="form__input-wrapper">
                                <label className='form__label' htmlFor="blog-link">Blog Link</label>
                                <input className='form__input'
                                    type="url"
                                    id='blog-link'
                                    name='link'
                                    placeholder='Insert link'
                                  onChange={(e)=>{
                                    setLink(e.target.value)
                                  }}
                                    required
                                />
                                <small>
                                    <strong>
                                        Links should start with http:// or https://
                                    </strong>
                                </small>
                            </div>

                        </div>
                        <button className='form__btn' onClick={(e)=>{
                            e.preventDefault()
                            postData()
                        }}>Add</button>
                    </form>
                </FormSection>

             
                    {getData?.map((props)=>(
                           <Blogs key={props._id}>
 <ul className='blogs__list'>
 <li>

     <article className='article'>
         <div className='article__text'>
             <h3 className='article__heading'  blogtitle="thei si the blog">{props.title}</h3>
             <p className='article__p'>
               {props.description}
             </p>
         </div>
         <menu className='article__menu'>
             <li>
                 <Link className='article__link' to={`/blog/update/${props._id}`}>View</Link>
             </li>
             <li>
                 <button className='article__delete-btn' onClick={(e)=>{
                    e.preventDefault()
                    removeData(props._id)
                 }}>
                     <FontAwesomeIcon icon='fa-solid fa-trash-can' /> Delete
                 </button>
             </li>
         </menu>
     </article>
 </li>
 {/* <li>
     <article className='article'>
         <div className='article__text'>
             <h3 className='article__heading'>Cybersecurity in Nigeria</h3>
             <p className='article__p'>
                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices felis. loren mioo moiod Lorem ipsum dolor sit amet, consectetur
                 adipiscing elit. Ultrices felis Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam, ullam!
             </p>
         </div>
         <menu className='article__menu'>
             <li>
                 <Link className='article__link' to='/blog/update'>View</Link>
             </li>
             <li>
                 <button className='article__delete-btn'>
                     <FontAwesomeIcon icon='fa-solid fa-trash-can' /> Delete
                 </button>
             </li>
         </menu>
     </article>

 </li> */}
 {/* <li> */}
     {/* <article className='article'>
         <div className='article__text'>
             <h3 className='article__heading'>Cybersecurity in Nigeria</h3>
             <p className='article__p'>
                 Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ultrices felis. loren mioo moiod Lorem ipsum dolor sit amet, consectetur
                 adipiscing elit. Ultrices felis Lorem ipsum dolor sit amet consectetur adipisicing elit. Nam, ullam!
             </p>
         </div>
         <menu className='article__menu'>
             <li>
                 <Link className='article__link' to='/blog/update'>View</Link>
             </li>
             <li>
                 <button className='article__delete-btn'>
                     <FontAwesomeIcon icon='fa-solid fa-trash-can' /> Delete
                 </button>
             </li>
         </menu>
     </article> */}
 {/* </li> */}
</ul>

</Blogs>
                    ))}
                   

            </main>
        </>
    )
}

export default AddBlog;