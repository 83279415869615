import { Link, Navigate } from "react-router-dom";
import React, { useState } from "react";

import styled from "styled-components";

import HeroCarousel from "../home/HeroCarousel";
import Footer from "../Footer";

import { convertHexToRgba } from "../../utils/convertHexToRgba";

// Images
import aboutGcetImage from "../../assets/images/home/about-gcet-img.jpg";
import provenExperienceImage from "../../assets/images/home/prove-experience-img.png";
import { PartnersLogo  } from "./PartnersLogo";


import heroBgLeft from "../../assets/images/home/hero-bg-left.svg";
import heroBgRight from "../../assets/images/home/hero-bg-right.svg";
import axios from "axios";
import Swal from "sweetalert2";
import { ServicesFormRequest } from "./ServicesFormRequest";

const Header = styled.header`
  background-image: url(${heroBgLeft}), url(${heroBgRight});
  background-color: ${({ theme }) => theme.colors.mediumBlue};
  background-repeat: no-repeat;
  background-position: left bottom, right top;
  color: ${({ theme }) => theme.colors.white};

  min-height: 100vh;
  padding: 2em 5%;

  @media (min-width: 1100px) {
    & {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
`;

const Main = styled.main`
  color: ${({ theme }) => theme.colors.darkGray};

  h2 {
    font-size: 2rem;
    font-weight: 700;
    margin: 0.5em 0;
  }

  p {
    line-height: 1.5625;
  }
`;

const Services = styled.section`
  margin: 6em 5%;

  .services__text {
    line-height: 1.5;
    margin: 0 auto;
    max-width: 800px;
  }

  .services__link {
    background: ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 0.25em;
    display: block;
    font-weight: 700;
    margin: 2em auto;
    padding: 0.625em 0.875em;
    width: fit-content;
    text-align: left;
  }
  .services__link:hover {
    background: ${({ theme }) => theme.colors.darkGray};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 0.25em;
    display: block;
    font-weight: 700;
    margin: 2em auto;
    padding: 0.625em 0.875em;
    width: fit-content;
  }

  @media (min-width: 700px) {
    .services__text {
      text-align: center;
    }
  }

  @media (min-width: 1100px) {
    & {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
`;

const About = styled.section`
  padding: 3em 5%;

  p {
    margin: 1em 0;
  }

  .about__fig {
    display: flex;
    flex-direction: column;
    gap: calc(2em + 7.5vw);
  }

  .about__img {
    // border-radius: 1.25em;
    border-radius: 1em;
  }

  .about__link {
    background: ${({ theme }) => theme.colors.lightBlue};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 0.25em;
    display: block;
    font-weight: 700;
    margin: 2em 0;
    padding: 0.625em 0.875em;
    width: fit-content;
  }
  .about__link:hover {
    background: ${({ theme }) => theme.colors.darkGray};
    color: ${({ theme }) => theme.colors.white};
  }

  @media (min-width: 700px) {
    .about__fig {
      flex-direction: row;
      align-items: center;
    }

    .about__figc,
    .about__img-c {
      flex: 400px;
    }

    .about__figc {
      max-width: 600px;
    }

    // .about__img-c {
    //     max-width: fit-content;
    // }
  }

  @media (min-width: 1100px) {
    & {
      padding-left: 120px;
      padding-right: 120px;
    }
  }
`;

const Experience = styled.section`
  color: ${({ theme }) => convertHexToRgba(theme.colors.white)};
  // margin: 5em 5%;
  margin: 5em 5%;

  .experience__heading {
    margin: 0.5em 0;
  }

  .experience__fig {
    display: flex;
    flex-direction: column;
    // align-items:center;
    // column-gap: calc(2em + 5vw);
    height: 100%;
  }

  .experience__img-c {
    // max-width: 610px;
    display: flex;
  }
  .experience__img {
    height: 100% !important;
  }

  .experience__figc {
    // max-width: 500px;
    background: #456387;
    display: flex;
    align-item: center;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
    margin: 0 !important;
  }
  .experience__figc p {
    max-width: 500px;
  }

  .experience__br {
    display: none;
  }

  @media (min-width: 700px) {
    // margin:0;
    margin: 5em 0 !important;
    .experience__fig {
      // background: #456387;
      flex-direction: row;
      // align-items: center;
    }

    .experience__figc {
      // max-width: 500px;
      background: #456387;
      // display:flex;
      // align-item:center;
      // justify-content:center;
      // flex-direction:column;
      padding: 5rem 6rem;
      // margin:0  !important;
    }

    // .experience__img-c,
    // .experience__figc {
    //   flex: 1;
    // }

    .experience__br {
      display: block;
    }
  }

  @media (min-width: 1100px) {
    & {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
`;

const Request = styled.section`
  color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, 0.9)};
  margin: 8em 5%;

  display: flex;
  flex-direction: column;
  gap: 2em;

  a {
    color: inherit;
  }

  .request__form {
    // background: #F9FFFF;
    border: 1px solid rgba(0, 0, 4, 0.1);
    border-radius: 2.5em;
    padding: 1.5em;

    display: grid;
    gap: 1.5em 1em;
  }

  .form__input-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }

  .form__label {
    font-weight: 700;
  }

  .form__select,
  .form__input {
    background: transparent;
    border: 1px solid rgba(14, 11, 43, 0.2);
    border-radius: 0.25em;
    font-size: 0.875rem;
    padding: 0.5em 1em;
    width: 100%;

    &:focus {
      outline: 2px solid ${({ theme }) => theme.colors.darkGray};
    }
  }

  .form__input {
    /* Chrome/Opera/Safari */
    &::-webkit-input-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.black, 0.2)};
    }

    /* Firefox 19+ */
    &::-moz-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.black, 0.2)};
    }

    /* IE 10+ */
    &:-ms-input-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.black, 0.2)};
    }

    /* Firefox 18- */
    &:-moz-placeholder {
      color: ${({ theme }) => convertHexToRgba(theme.colors.black, 0.2)};
    }
  }

  .form__btn {
    background: ${({ theme }) => theme.colors.lightBlue};
    border: none;
    cursor: pointer;
    border-radius: 0.5em;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.125rem;
    margin-top: 1em;
    padding: 0.625em 0.875em;
  }
  .form__btn:hover {
    background: ${({ theme }) => theme.colors.darkGray};
  }
  @media (min-width: 700px) {
    & {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .request__text,
    .request__form {
      flex: 1;
    }

    .request__text {
      max-width: 500px;
    }

    .request__form {
      grid-template-columns: repeat(2, 1fr);
      max-width: 400px;
    }

    .form__input-wrapper:first-child,
    .form__btn {
      grid-column: 1 / -1;
    }
  }

  @media (min-width: 1100px) {
    & {
      margin-left: 120px;
      margin-right: 120px;
    }
  }
`;



const Home = () => {
  return (
    <>
      <HeroCarousel />

      <Main>
        <Services>
          <div className="services__text">
            <h2>What we do Differently</h2>
            <p>
              Global Concept Emerge Technologies Limited is a team of
              infrastructure, network &amp; security certified experts and
              specialists who deliver IT solutions that are transparent,
              flexible and robust enough to drive customers' organizational and
              business goals.
            </p>

            <Link className="services__link" to="/consultancy">
              Get Consultancy
            </Link>
          </div>
        </Services>
        <About>
          <figure className="about__fig">
            <figcaption className="about__figc">
              <h2>
                Know About{" "}
                <abbr title="Global Concept Emerge Technologies">GCET</abbr>
              </h2>
              <p>
                Global Concept Emerge Technologies Limited is an IT Solutions
                delivery company incorporated under the Companies and Allied
                Matters Act 1990, to undertake and provide various information
                technology, as well as advisory services to all sectors of the
                Nigerian economy including Government departments &amp; as well
                as educational institutions.
              </p>
              <p>
                As certified technology company with interest in carefully
                tailored Information Technology Solutions, we are geared solely
                towards Consulting, Enterprise solutions architecture, Service
                delivery and implementation of both Networks, Security and
                Infrastructure.
              </p>
              <Link className="about__link" to="/about">
                Learn More
              </Link>
            </figcaption>
            <div className="about__img-c">
              <img className="about__img" src={aboutGcetImage} alt="" />
            </div>
          </figure>
        </About>
        <Experience>
          <figure className="experience__fig">
            <div className="experience__img-c">
              <img
                className="experience__img"
                src={provenExperienceImage}
                alt=""
              />
            </div>
            <figcaption className="experience__figc">
              <h2 className="experience__heading">
                3 Decades of <br className="experience__br" /> Combined
                Experience
              </h2>
              <p>
                <span style={{ fontWeight: "700" }}>
                  Our promise to you is this;
                </span>{" "}
                <br /> From the general to the more specific solutions, we will
                help you do the most with the least required, while making the
                Complex Look Simple.
              </p>
            </figcaption>
          </figure>
        </Experience>
        <ServicesFormRequest />
        <PartnersLogo />
      </Main>

      <Footer />
    </>
  );
};

export default Home;
