import { useState } from 'react';

import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertHexToRgba } from '../../utils/convertHexToRgba';

import logo from '../../assets/images/logo-header.png';
// import axios from "axios"
import { useSelector } from 'react-redux';
import axios from "axios"
import Swal from "sweetalert2"
const getFileSize = (size) => {
    if (size < 1024) {
        return `${size} bytes`;
    } else if (size < 1024 ** 2) {
        return `${(size / 1024).toFixed(2)}KB`;
    } else {
        return `${(size / (1024 ** 2)).toFixed(2)}MB`;
    }
}

const Header = styled.header`
    background: ${({ theme }) => theme.colors.mediumBlue};

    padding: 1em 5%;

    .header__logo {
        width: 100px;
    }

    @media (min-width: 1100px) {
      & {
        padding-left: 120px;
        padding-right: 120px;
      }
    }
`;

const FormSection = styled.section`

    margin: 0 auto;
    padding: 3em 5%;
    max-width: 1140px;

    .form-section__heading {
        font-size: 2.5rem;
        margin: .5em 0;
        text-align: center;
    }

    .form__inputs {
        margin: 3em 0;

        display: grid;
        gap: 1.5em calc(1em + 5%);
    }

    .form__input-wrapper {
        display: flex;
        flex-direction: column;
        gap: .625em;

    }

    .form__label {
        font-size: 1.125rem;
        font-weight: 600;
    }

    .form__input,
    .form__label--file-btn {
        border: 1px solid ${({ theme }) => theme.colors.darkGray};
        border-radius: .625em;
        font-size: 1rem;
        padding: .8125em 1.25em;
        width: 100%;
    }
    
    .form__input {
        
        /* Chrome/Opera/Safari */
        &::-webkit-input-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .7)};
        }

        /* Firefox 19+ */
        &::-moz-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .7)};
        }

        /* IE 10+ */
        &:-ms-input-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .7)};
        }
        
        /* Firefox 18- */
        &:-moz-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .7)};
        }
    }

    .form__input--file {
        height: .1px;
        width: .1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;


        :hover ~ .form__label--file-btn,
        :focus ~ .form__label--file-btn {
            outline: 1px solid ${({ theme }) => theme.colors.darkGray};
        }
    }

    .form__label--file-btn {
         background: var(--white);
        color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .7)};
        cursor: pointer;
        
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1em;
        
    }

    .file-output__span {
        font-weight: 600;
    }

    .file-preview {
        border: 1px solid ${({ theme }) => theme.colors.darkGray};
        border-radius: .3125em;
        position: relative;
        padding: .75em .5em;
        
        height: auto;
        max-height: 250px;
        overflow: hidden;
    }

    .file-preview__close-btn {
        background: ${({ theme }) => theme.colors.darkGray};
        border: none;
        border-radius: 50%;
        color: ${({ theme }) => theme.colors.white};
        cursor: pointer;
        font-size: 1.125rem;

        position: absolute;
        padding: 1em;
        height: 30px;
        width: 30px;

        display: grid;
        place-content: center;

        left: 5%;
        top: 10%;
    }
    
    .file-preview__img {
        border-radius: .3125em;
        width: 100%;
        height: 100%;
    }
    
    .form__btn {
        background: ${({ theme }) => theme.colors.lightBlue};
        border: none;
        border-radius: .5em;
        color: ${({ theme }) => theme.colors.white};
        cursor: pointer;
        display: block;
        font-size: 1.25rem;
        font-weight: 700;
        margin: 0 auto;
        max-width: 560px;
        padding: .5em .625em;
        width: 100%;
    }

    @media (min-width: 700px) {
        .form__inputs {
            grid-template-columns: repeat(2, 1fr);
            justify-content: space-between;
        }

        .form__input-wrapper:last-child {
            grid-column: 1 / -1;
        }
    }

    
`;

const UpdateBlog = ({blogtitle}) => {

    const {id} = useParams()
    console.log(id)
    const user = useSelector((state)=>state.recentuser)
    // const [getData, setGetData] = useState([])
    const navigate= useNavigate()
    const [base64code , setBase64code]= useState("")
    const [title, setTitle]=useState("")
    const [datee, setDatee] = useState("")
    const [writerName, setWriterName]=useState("")
    const [link, setLink]=useState("")
    const onChange=(e)=>{
        const file = e.target.files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload=()=>{
            onload(reader.result)
        }
        reader.onerror=(error)=>{
            console.log("error", error)

        }
    }

    const onload = (fileString)=>{
        setBase64code(fileString)
    }
    const EditData = async()=>{
        const url = `http://localhost:1233/api/gcet/editBlog/${id}`
        const config={
            headers:{
                authorization : `dee ${user.token}`
            }
        }
        axios.patch(url, {imagez:base64code, title:title, writerName:writerName, description:datee, link:link}, config ).then((res)=>{
            // console.log(res)
     navigate("/blog/add")

        }).catch((error)=>{
            console.log(error)
        })
        // window.location.reload()
    }
    // const [formDetails, setFormDetails] = useState({
    //     name: '',
    //     date: '',
    //     title: '',
    //     link: ''
    // });

    // const [file, setFile] = useState(null);
    // const [imageUrl, setImageUrl] = useState('');

    // const handleInputChange = ({ target }) => {
    //     setFormDetails(prevState => ({ ...prevState, [target.name]: target.value }));
    // }
    
    // const handleFileChange = ({ target }) => {
        
    //     if (target.files[0]?.name) {
    //         setFile(target.files[0]);
    
    //         setImageUrl(URL.createObjectURL(target.files[0]));
    //     }
    // }
    
    // const removeFile = () => {
    //     URL.revokeObjectURL(imageUrl);

    //     setFile(null);
    //     setImageUrl('');
    // }

    // const handleSubmit = (event) => {
    //     event.preventDefault();

    //     const formData = new FormData();

    //     for (const [key, value] of Object.entries(formDetails)) {
    //         formData.append(key, value);
    //     }

    //     formData.append('image', file);

    //     // Send formData to backend
    // }

    return (
        <>
            <Header>
                <Link to='/'>
                    <img className='header__logo' src={logo} alt="GCET logo" />
                </Link>
            </Header>
            <main>
                <FormSection>

                    <h1 className='form-section__heading'>Blog</h1>

                    <form>
                        <div className="form__inputs">
                            <div className="form__input-wrapper">
                                <label className='form__label' htmlFor="writer-name">Writer's Name</label>
                                <input className='form__input'
                                    type="text"
                                    id='writer-name'
                                    name='name'
                                    placeholder='Insert name'
                                    onChange={(e)=>{
                                        setWriterName(e.target.value)
                                      }}
                                    required
                                />
                            </div>
                            <div className="form__input-wrapper">
                                <label className='form__label' htmlFor="date">Brief Description</label>
                                <input className='form__input'
                               
                                    id='date'
                                    name='date'
                                    onChange={(e)=>{
                                        setDatee(e.target.value)
                                      }}
                                    //   defaultValue={props.title}
                                  required  
                                />
                            </div>
                            <div className="form__input-wrapper">
                                <label htmlFor="file-input" className="form__label">Insert image</label>
                                <input
                                    id="file-input"
                                    type="file"
                                    className="form__input form__input--file"
                                    name="image"
                                    accept='image/*'
                                    onChange={()=>{
                                        // handleFileChange()
                                        onChange()
                                    }}
                                    required
                                />
                                <label htmlFor="file-input" className="form__label--file-btn">
                                    Upload image
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-up-from-bracket" />
                                </label>

                                {/* {
                                    file?.name && (<>
                                        <output id="file-details" htmlFor="file-input">
                                            <small>
                                                <span className="file-output__span">File details</span>: {file.name} - {getFileSize(file.size)}
                                            </small> */}
                                        {/* </output>
                                        <div className="file-preview">
                                            <button onClick={removeFile} className='file-preview__close-btn'>
                                                <FontAwesomeIcon icon='fa-solid fa-xmark' />
                                            </button>
                                            <img className='file-preview__img' src={imageUrl} alt="" />
                                        </div>
                                    </>
                                    )
                                } */}
                            </div>
                            <div className="form__input-wrapper">
                                <label className='form__label' htmlFor="blog-title">Blog Title</label>
                                <input className='form__input'
                                    type="text"
                                    id='blog-title'
                                    name='title'
                                    placeholder='Insert title'
                                    onChange={(e)=>{
                                        setTitle(e.target.value)
                                      }}
                                  required  
                                  defaultValue={blogtitle}
                                />
                            </div>
                            <div className="form__input-wrapper">
                                <label className='form__label' htmlFor="blog-link">Blog Link</label>
                                <input className='form__input'
                                    type="url"
                                    id='blog-link'
                                    name='link'
                                    placeholder='Insert link'
                                    onChange={(e)=>{
                                        setLink(e.target.value)
                                      }}
                                      required
                                />
                                <small>
                                    <strong>
                                        Links should start with http:// or https://
                                    </strong>
                                </small>
                            </div>

                        </div>
                        <button className='form__btn' onClick={(e)=>{
                            e.preventDefault()
                            EditData()
                        }}>Update</button>
                    </form>
                </FormSection>

            </main>
        </>
    )
}

export default UpdateBlog