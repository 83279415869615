import { useState } from "react";

import { Link } from "react-router-dom";

import styled from "styled-components";

import { Pagination, Autoplay, Navigation, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

import Navbar from "../Navbar";

import { convertHexToRgba } from "../../utils/convertHexToRgba";

import heroBg1 from '../../assets/images/home/hero-bg-1.png';
import heroBg2 from '../../assets/images/home/hero-bg-2.png';
import heroBg3 from '../../assets/images/home/hero-bg-3.png';

const SwiperSC = styled(Swiper)`
    background: linear-gradient(${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, 0.4)}, 
        ${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, 0.4)}), 
        url(${({ $currentSlide }) => $currentSlide === 0 ? heroBg1 : $currentSlide === 1 ? heroBg2 : heroBg3});

    background-color: ${({ theme }) => theme.colors.mediumBlue};
    background-size: cover;
    background-repeat: no-repeat;
    
    color: ${({ theme }) => theme.colors.white};

    min-height: 100vh;
    padding: 2em 5%;
    
   
    @media (min-width: 1100px) {
      & {
        padding-left: 120px;
        padding-right: 120px;
      }
    }
    .swiper-wrapper {
        padding-top: 7em;
    }

    .swiper-pagination {
        bottom: 30px;
    }
    .swiper-button-next, .swiper-button-prev {
        color: white;
        background:rgba(99, 129, 165, 0.7);
        padding:1rem 1rem;
        border-radius:50%;
        height:60px;
        width:60px;
        
    }
    @media screen and  (max-width:580px){
        .swiper-button-next, .swiper-button-prev {
            color: white;
            background:rgba(99, 129, 165, 0.7);
            padding:1rem 1rem;
            border-radius:50%;
            height:30px;
            width:30px;
            
        }
    }
    
   

    .swiper-pagination-bullet {
        background: ${({ theme }) => convertHexToRgba(theme.colors.white, .5)};
    }
    
    .swiper-pagination-bullet-active {
        background: ${({ theme }) => theme.colors.white};
    }    
    
    .hero__heading {
        font-size: 3.125rem;
        font-weight: 800;
        margin-bottom: .5em;
        text-align:center;
    }
    
    .hero__p {
        display:flex;
        justify-content:center;
        align-items:center;
        font-size: 1.125rem;
        line-height: 1.5625;
        max-width:500px;
        margin:0 auto;
        text-align:center;
    }

    .hero__link-btns {
        display: flex;
        align-items-center;
        justify-content:center;
        text-align:center;
        flex-wrap: wrap;
        gap: 1em;
        margin: 2em 0;
    }
    
    .hero__link-btn {
        border-radius: .25em;
        font-size: 1.125rem;
        font-weight: 600;
        display: block;
        padding: .625em .875em;
        
        &--consultancy {
             background: ${({ theme }) => theme.colors.lightBlue};
            
            color: ${({ theme }) => theme.colors.white};
        }

        &--consultancy:hover{
            background:white;
            color: ${({ theme }) => theme.colors.lightBlue};
        }

        &--call {
            background: transparent;
            color: white;
            border:1px solid white;
        }
        &--call:hover{
            background:${({ theme }) => theme.colors.darkGray};
            color:white;
        }
    }

    
`;

const HeroCarousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    return (
        <SwiperSC
        style={{
            
            "--swiper-navigation-size": "15px",
          }}
            modules={[Pagination, Navigation, Autoplay, EffectFade]}
            pagination={{ clickable: true }}
            loop={true}
            effect='fade'
            navigation
            fadeEffect={{
                crossFade: true
            }}
            autoplay={{
                delay: 7000,
                disableOnInteraction: false
            }}
            
            // onSwiper={(swiper) => console.log(swiper)}
            onSlideChange={({ realIndex }) => setCurrentSlide(realIndex)}

            $currentSlide={currentSlide}
        >
            <SwiperSlide>
            {/* ${({ theme }) => theme.colors.lightBlue} */}
                <article className="hero__article hero__article--1">
                    <h1 className="hero__heading">Grow Your Business</h1>
                    <p className="hero__p">
                        We help businesses build their IT organisation from the
                        heart of growth and innovation to increased all-round
                        efficiency.
                    </p>
                    <menu className="hero__link-btns">
                        <li>
                            <Link className='hero__link-btn hero__link-btn--consultancy' to='/consultancy'>Get Consultancy</Link>

                        </li>
                        <li>
                            <a href='#request' className='hero__link-btn hero__link-btn--call'>Quick Call Back</a>
                        </li>
                    </menu>
                </article>
            </SwiperSlide>
            <SwiperSlide>
                <article className="hero__article hero__article--2">
                    <h1 className="hero__heading">Plan Your Dream</h1>
                    <p className="hero__p">
                        You have an IT roadmap, or have not developed one yet, we are here to help you build your IT dream from planning phase to full execution.
                    </p>
                    <menu className="hero__link-btns">
                        <li>
                            <Link className='hero__link-btn hero__link-btn--consultancy' to='/consultancy'>Get Consultancy</Link>

                        </li>
                        <li>
                            <a href='#request' className='hero__link-btn hero__link-btn--call'>Quick Call Back</a>
                        </li>
                    </menu>
                </article>
            </SwiperSlide>
            <SwiperSlide>
                <article className="hero__article hero__article--3">
                    <h1 className="hero__heading">Make Your Dream True</h1>
                    <p className="hero__p">
                        We understand that all thriving global businesses rely on technology to sustain growth, we are your right partner for IT
                        initiatives, implementation and support.
                    </p>
                    <menu className="hero__link-btns">
                        <li>
                            <Link className='hero__link-btn hero__link-btn--consultancy' to='/consultancy'>Get Consultancy</Link>
                        </li>
                        <li>
                            <a href='#request' className='hero__link-btn hero__link-btn--call'>Quick Call Back</a>
                        </li>
                    </menu>
                </article>
            </SwiperSlide>
            <div slot='container-start'>
                <Navbar />
            </div>
        </SwiperSC>
    )
}

export default HeroCarousel;