import styled from 'styled-components';
import { ServicesFormRequest } from '../ServicesFormRequest';

import Navbar from '../../Navbar';
import Footer from '../../Footer';
import { PartnersLogo } from '../PartnersLogo';
import { convertHexToRgba } from '../../../utils/convertHexToRgba';

import ITAdvisoryHeroBg from '../../../assets/images/services/it-advisory-hero-bg.jpg';
import ITAdvisoryOverview from '../../../assets/images/services/it-advisory-overview.png';


const Header = styled.header`
    background-image: linear-gradient(${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, .7)}, ${({ theme }) => convertHexToRgba(theme.colors.mediumBlue, .7)}), 
      url(${ITAdvisoryHeroBg});
    background-repeat: no-repeat;
    background-size: cover;
    color: ${({ theme }) => theme.colors.white};

    min-height: min(100vh, 520px);
    padding: 2em 5%;

    display: grid;
    align-items: start;

    h1 {
      font-size: 2.5rem;
      font-weight: 700;
    }

    @media (min-width: 1100px) {
      & {
        padding-left: 120px;
        padding-right: 120px;
      }
    }
`;

const Main = styled.main`
    color: ${({ theme }) => theme.colors.darkGray};

    h2 {
        font-size: 2rem;
        font-weight: 700;
        margin: .5em 0;
    }

    p {
        line-height: 1.5625;
    }
`;

const Overview = styled.section`
  margin: 3em 5%;

  display: flex;
  flex-direction: column;
  gap: 2em calc(2em + 5vw);

  .overview__heading {

    color: ${({ theme }) => theme.colors.mediumBlue};
    font-size: 2rem;
    font-weight: 600;
}

  .overview__children {
    line-height: 2.1;
    // padding-top:1rem;


    > * {
      margin-bottom: 1.5em;
    }
  }

  .overview__children p{
    line-height: 2.1;

  }

  @media (min-width: 800px) {
    & {
      flex-direction: row;
    }
    
    .overview__left,
    .overview__right {
      flex: 1;
    }

    .overview__left {
      max-width: 440px;
      margin-top:1rem;
    }
    .overview__left img{
      min-height:480px;
      border-radius:3px;
   }

    .overview__right {
      max-width: 700px;
    }
  }

  @media (min-width: 1100px) {
      & {
        margin-left: 120px;
        margin-right: 120px;
      }
    }
`;


const Overvieww = styled.section`
  margin: 3em 5%;

  display: flex;
  flex-direction: column;
  gap: 2em calc(2em + 5vw);

  .overview__heading {

    color: ${({ theme }) => theme.colors.mediumBlue};
    font-size: 2rem;
    font-weight: 600;
   
  }

  .overview__children {
    line-height: 2.1;

    > * {
      margin: 1.5em 0;
    }
  }

  @media (min-width: 800px) {
    & {
      flex-direction: row;
    }
    
    .overview__left,
    .overview__right {
      flex: 1;
    }

    .overview__left {
        max-width: 440px;
        margin-top:1rem;
      }


    .overview__left img{
        min-height:400px !important;
        border-radius:3px;
     }

    .overview__right {
      max-width: 700px;
    }
  }

  @media (min-width: 1100px) {
      & {
        margin-left: 120px;
        margin-right: 120px;
      }
    }
`;

const Request = styled.section`
    color: ${({ theme }) => convertHexToRgba(theme.colors.darkGray, .9)};
    margin: 8em 5%;

    display: flex;
    flex-direction: column;
    gap: 2em;

    a {
        color: inherit;
    }

    .request__form {
        // background: #F9FFFF;
        border: 1px solid rgba(0, 0, 4, 0.1);
        border-radius: 2.5em;
        padding: 1.5em;

        display: grid;
        gap: 1.5em 1em;
    }

    .form__input-wrapper {
        display: flex;
        flex-direction: column;
        gap: .5em;
    }

    .form__label {
        font-weight: 700;
    }

    .form__select,
    .form__input {
        background: transparent;
        border: 1px solid rgba(14, 11, 43, 0.2);
        border-radius: .25em;
        font-size: .875rem;
        padding: .5em 1em;
        width: 100%;
        
        &:focus {
            outline: 2px solid ${({ theme }) => theme.colors.darkGray};
        }
    }

    .form__input {

        /* Chrome/Opera/Safari */
        &::-webkit-input-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }

        /* Firefox 19+ */
        &::-moz-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }

        /* IE 10+ */
        &:-ms-input-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }

        /* Firefox 18- */
        &:-moz-placeholder {
            color: ${({ theme }) => convertHexToRgba(theme.colors.black, .2)};
        }
    }

    .form__btn {
        background: ${({ theme }) => theme.colors.lightBlue};
        border: none;
        cursor:pointer;
        border-radius: .5em;
        color: ${({ theme }) => theme.colors.white};
        font-size: 1.125rem;
        margin-top: 1em;
        padding: .625em .875em;
    }
    .form__btn:hover {
        background: ${({ theme }) => theme.colors.darkGray};
        
    }
    @media (min-width: 700px) {
        & {
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }
        .request__text,
        .request__form {
            flex: 1;
        }
    

        .request__text {
            max-width: 500px;
        }

        .request__form {
            grid-template-columns: repeat(2, 1fr);
            max-width: 400px;
        }
       

        .form__input-wrapper:first-child,
        .form__btn {
            grid-column: 1 / -1;
        }
    }

    @media (min-width: 1100px) {
      & {
        margin-left: 120px;
        margin-right: 120px;
      }
    }
`;


const Partners = styled.section`
    margin: 5em 5% 10em;
    
    .partners__heading {
        text-align: center;
    }
    
    .partners__logos {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: center;
        gap: 2em;
        margin-top: 3em;
    }
    
    .partners__logo-c {
        border-radius: .625em;
        flex: 145px;
        max-width: 160px;
        min-height: 100px;
        overflow: hidden;
        padding: .5em 0;
        box-shadow: 0px 4px 4px rgba(69, 99, 135, 0.08);
        
        display: grid;
        place-items: center;
    }
    .partners__logo-c:hover {
        box-shadow: 0px 4px 4px rgba(69, 99, 135, 0.11);
    }

    @media (min-width: 1100px) {
      & {
        margin-left: 120px;
        margin-right: 120px;
      }
    }
`;


const ItAdvisory = () => {

    return (
        <>
            <Header>
                <Navbar />
                <h1 style={{'textAlign':'center'}}>IT Advisory and Managed Services <br />
                (IAMS)
                    </h1>
            </Header>
            <Main>
                <Overview>
                    <div className='overview__left'>
                        <img src={ITAdvisoryOverview} alt="" />
                    </div>
                    <div className='overview__right'>
                    <h2 className='overview__heading'>Overview</h2>
                    <div className="overview__children">

                        <p>
                            Under the Services portfolio, GCET provides consulting, managed and professional services to customers, first around
                            all our core solutions and then in specialized areas like security, automation, tuning, customization /special /
                            customer-specific archiving, processes, etc., that are not off the shelf.
                        </p>
                        <p>
                            With our experience from global IT organizations like Microsoft, Cisco, IBM, CheckPoint, Sophos, Barracuda and with our
                            ITIL, Project Management, architecture and other exposures we have gained over the years and our partnership with
                            individual and teams of experts around the world, we have a service that helps customers improve their operations from
                            process initiation, design and or optimization to architecture designs, integration into service management to solution
                            evaluation and documentation.
                        </p>
                    </div>

                    </div>
                </Overview>
          
                <ServicesFormRequest />

               <PartnersLogo />
            </Main>
            <Footer />
        </>
    )
}

export default ItAdvisory;